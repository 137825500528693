import * as React from "react"
import Layout from "../components/layout"
import workData from "../data/mywork.json"
import "../styles/mywork.css"

const MyWork = (location) => {
    // Group entries by year and month
    const groupedEntries = workData.entries.reduce((acc, entry) => {
        const [day, month, year] = entry.date.split('/')
        const yearKey = year
        const monthKey = `${year}-${month.padStart(2, '0')}`
        
        if (!acc[yearKey]) {
            acc[yearKey] = {}
        }
        if (!acc[yearKey][monthKey]) {
            acc[yearKey][monthKey] = []
        }
        
        acc[yearKey][monthKey].push(entry)
        return acc
    }, {})

    // Sort years in descending order
    const sortedYears = Object.keys(groupedEntries).sort((a, b) => b - a)

    return (
        <Layout location={location} title='My Work'>
            <div>
                {sortedYears.map(year => (
                    <div key={year} className="year-section">
                        <h2 className="year-header">{year}</h2>
                        <div className="calendar-grid">
                            {Object.keys(groupedEntries[year])
                                .sort((a, b) => b.localeCompare(a)) // Sort months in descending order
                                .map(monthKey => {
                                    const month = new Date(monthKey + '-01').toLocaleString('default', { month: 'long' })
                                    return (
                                        <div key={monthKey} className="month-box">
                                            <h3 className="month-header">{month}</h3>
                                            {groupedEntries[year][monthKey].map((entry, index) => (
                                                <div key={index} className="entry">
                                                    <span className="entry-date">{entry.date}</span>
                                                    <span className="entry-content">
                                                        {entry.link ? (
                                                            <a href={entry.link}>{entry.title}</a>
                                                        ) : (
                                                            entry.title
                                                        )}
                                                        {entry.description && ` - ${entry.description}`}
                                                        {entry.additionalLink && (
                                                            <> (see the <a href={entry.additionalLink.url}>{entry.additionalLink.text}</a>)</>
                                                        )}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                ))}
            </div>
        </Layout>
    );
};

export default MyWork;
